import { LoaderFunction, MetaFunction } from "@remix-run/cloudflare";

import type { Urls } from "~/lib/get-urls";

export function getMeta(
  req: Readonly<{ desc: string; title: string; urls: Urls }>
) {
  return getMetaWithUrl({ ...req, url: req.urls.envDependent });
}

export function getMetaWithUrl({
  desc,
  title,
  url,
}: Readonly<{ desc: string; title: string; url: string }>) {
  return [
    { name: "description", content: desc },
    { property: "og:description", content: desc },
    { property: "og:title", content: title },
    { property: "og:url", content: url },
    { title },
    { name: "twitter:description", content: desc },
    { name: "twitter:title", content: title },
    {
      tagName: "link",
      rel: "canonical",
      href: url,
    },
  ];
}

// https://gist.github.com/ryanflorence/ec1849c6d690cfbffcb408ecd633e069?permalink_comment_id=4706751#gistcomment-4706751
export const mergeMeta = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction | unknown> = Record<
    string,
    unknown
  >
>(
  leafMetaFn: MetaFunction<Loader, ParentsLoaders>
): MetaFunction<Loader, ParentsLoaders> => {
  return (arg) => {
    const leafMeta = leafMetaFn(arg);

    return arg.matches.reduceRight((acc, match) => {
      for (const parentMeta of match.meta) {
        const index = acc.findIndex(
          (meta) =>
            ("name" in meta &&
              "name" in parentMeta &&
              meta.name === parentMeta.name) ||
            ("property" in meta &&
              "property" in parentMeta &&
              meta.property === parentMeta.property) ||
            ("title" in meta && "title" in parentMeta)
        );
        if (index == -1) {
          // Parent meta not found in acc, so add it
          acc.push(parentMeta);
        }
      }
      return acc;
    }, leafMeta);
  };
};
